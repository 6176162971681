<template>
  <div
      class="gap-x-2 inline-flex items-center font-semibold text-xs text-center group-hover/button:border-brand-primary duration-300 ease-in-out">
    <div class="selected-language" @click="toggleDropdown">
      <span class="flag text-xs text-center">{{ availableLanguages[currentLanguage ?? 'en']?.icon }}</span>
      <span
          class="i-material-symbols:keyboard-arrow-down inline-block text-base mt-.5 group-hover/button:translate-x-1 duration-300 ease-in-out"/>
    </div>
    <ul class="language-dropdown border-brand-stroke/10 rounded-2 text-white" v-if="isDropdownOpen">
      <li class="hover:bg-brand-stroke/10 rounded-2" v-for="(lang, code) in availableLanguages" :key="code"
          @click="selectLanguage(code)">
        <span class="flag">{{ lang.icon }}</span>
        <span class="text-xs">{{ lang.label }}</span>
      </li>
    </ul>
  </div>
</template>

<script setup>
import {onActivated, onMounted, onUpdated, ref} from 'vue';
import {availableLanguages, defaultLang, supportedLanguages} from "@consts";
import {reverseObj} from "@lib/reverseObj";
import {useLocalStorage} from "@vueuse/core";
import {urlmap} from "../../../urlmap.ts";
import {useI18n} from "vue-i18n";

const currentLanguage = useLocalStorage(
    'selected-language',
    defaultLang,
);
const isDropdownOpen = ref(false);

const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
};

const selectLanguage = (desiredCode) => {

  const previousCode = currentLanguage.value;
  if (previousCode === desiredCode) {
    return;
  }

  const jumpUrl = calcJumpUrl(previousCode, desiredCode);

  // case2: none of the two languages is english:
  // make 2 jumps => lang1=>en=>lang2

  if (jumpUrl) {
    // goto that url
    currentLanguage.value = desiredCode;
    isDropdownOpen.value = false;
    window.location.href = jumpUrl;
  } else {
    // replace whatever is in the url with the desired language
    const currentUrl = decodeURIComponent(window.location.pathname);

    // if url contains "a language" => replace it with "b language"
    if (currentUrl.includes(`/${previousCode}/`)) {
      const newUrl = currentUrl.replace(`/${previousCode}/`, `/${desiredCode}/`);
      currentLanguage.value = desiredCode;
      isDropdownOpen.value = false;
      window.location.href = newUrl;
    } else {
      // means we're on a default url e.g: .com/blog (and not .com/en/blog)
      // so we need to add the desired language to the url
      const newUrl = `/${desiredCode}${currentUrl}`;
      currentLanguage.value = desiredCode;
      isDropdownOpen.value = false;
      window.location.href = newUrl;
    }
  }
};


function calcJumpUrl(previousCode, desiredCode) {
  let currentUrl = decodeURIComponent(window.location.pathname);

  // if "/en" to to "/ru", or if "/" goto "/ru"
  if(currentUrl === `/${previousCode}` || currentUrl === '/' && !!desiredCode){
    return `/${desiredCode}`;
  }

  // and symbol is: &&
  // or symbol is: ||
  if (currentUrl.startsWith('/blog/') &&
      !currentUrl.startsWith('/en/blog/') &&
      !currentUrl.endsWith('/blog/')) {

    currentUrl = currentUrl
        .replace('/blog/', '/en/blog/');
  }

  if (currentUrl === `/${previousCode}/blog` || currentUrl === `/blog`) {
    return `/${desiredCode}/blog`;
  }

  // from en => what-ever
  if (previousCode === 'en') {
    const translation = urlmap[previousCode][desiredCode];
    return translation[currentUrl];
  }

  // from what-ever => en
  if (desiredCode === 'en') {
    const translation = reverseObj(urlmap[desiredCode][previousCode]);
    return translation[currentUrl];
  }

  // from what-ever => what-ever
  const translation1 = reverseObj(urlmap['en'][previousCode]);
  const translation2 = urlmap['en'][desiredCode];

  return translation2[translation1[currentUrl]];
}

const {t, locale} = useI18n({
  useScope: 'global'
});

// This does not work on prod "back" button
onMounted(() => {
  syncCurrentLang();
});

onActivated(() => {
  syncCurrentLang();
});

onUpdated(() => {
  syncCurrentLang();
});

function syncCurrentLang() {

  const currentUrl = decodeURIComponent(window.location.pathname);

  const langInUrlPath = currentUrl.split('/').find((el) => supportedLanguages.includes(el));
  const langInUrl = fallbackToEnIfNotLangCode(langInUrlPath);
  const langInLS = currentLanguage.value;

  locale.value = langInUrl;

  if (langInUrl !== langInLS) {
    currentLanguage.value = langInUrl;
    locale.value = langInUrl;
  }
}

const fallbackToEnIfNotLangCode = (langCode) => {
  if (!langCode) {
    return defaultLang;
  }
  const exists = supportedLanguages.find((el) => el === langCode);
  return exists ?? defaultLang;
}

</script>

<style scoped>

.selected-language {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 4px;
}

.flag {
  font-size: 2em;
  margin-right: 5px;
}

.language-dropdown {
  position: absolute;
  margin-top: 1rem;
  top: 100%;
  left: 0;
  list-style: none;
  background-color: black;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.language-dropdown li {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  cursor: pointer;
}
</style>